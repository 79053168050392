/* App.css */

/* Diretivas do Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Seus estilos existentes */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Efeito de tremor */
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake {
  animation: shake 0.5s;
}

/* Transições suaves para ícones */
.transition-colors {
  transition-property: color;
}

.transition-opacity {
  transition-property: opacity;
}

.duration-300 {
  transition-duration: 300ms;
}

/* Estilos para o DatePicker */
.datePickerWrapper input {
  width: 100%;
  padding: 12px;
  background-color: #333; /* Fundo cinza */
  color: #fff;
  border: 1px solid #555;
  border-radius: 6px;
  font-size: 16px;
}

/* --- Estilos Personalizados para PhoneInput --- */

/* Container principal do PhoneInput */
.phone-input-container.react-tel-input {
  width: 100%;
  background-color: #333 !important; /* Fundo escuro */
  border-radius: 6px !important;
  border: 1px solid #555 !important;
  height: 50px !important; /* Aumenta a altura do container */
}

/* Botão de seleção de país */
.phone-input-container.react-tel-input .flag-dropdown {
  background-color: #333 !important; /* Fundo escuro */
  color: #ccc !important; /* Texto em cinza claro */
  height: 100% !important; /* Garante que o botão ocupe toda a altura do container */
}

/* Campo de entrada do PhoneInput */
.phone-input-container.react-tel-input .form-control {
  width: 100% !important;
  padding-left: 50px !important; /* Ajuste para acomodar o dropdown do país */
  background-color: #333 !important; /* Fundo escuro */
  color: #ccc !important; /* Texto em cinza claro */
  font-size: 16px !important;
  border: none !important;
  height: 100% !important; /* Garante que o campo de entrada ocupe toda a altura do container */
  box-sizing: border-box;
}

/* Placeholder do campo de entrada */
.phone-input-container.react-tel-input .form-control::placeholder {
  color: #aaa !important; /* Placeholder em cinza claro */
}

/* Dropdown do PhoneInput */
.phone-input-dropdown {
  background-color: #242424 !important; /* Fundo escuro */
  color: #ccc !important; /* Texto em cinza claro */
  border: 1px solid #555 !important;
  border-radius: 6px !important;
}

/* Itens do dropdown */
.phone-input-dropdown .country-list .country {
  background-color: #242424 !important; /* Fundo escuro */
  color: #ccc !important; /* Texto em cinza claro */
  padding: 10px !important;
  cursor: pointer;
}

/* Hover nos itens do dropdown */
.phone-input-container.react-tel-input .phone-input-dropdown .country-list .country:hover {
  background-color: #3a3a3a !important; /* Cor de fundo no hover */
  color: #fff !important; /* Texto branco no hover */
}

/* Campo de busca no dropdown */
.phone-input-dropdown .country-list .search input {
  background-color: #333 !important; /* Fundo escuro */
  color: #ccc !important; /* Texto em cinza claro */
  border: 1px solid #555 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 8px 12px !important;
}

/* Placeholder do campo de busca no dropdown */
.phone-input-dropdown .country-list .search input::placeholder {
  color: #aaa !important; /* Placeholder em cinza claro */
}

/* Scrollbar personalizada no dropdown (opcional) */
.phone-input-dropdown .country-list {
  scrollbar-width: thin;
  scrollbar-color: #555 #242424;
}

.phone-input-dropdown .country-list::-webkit-scrollbar {
  width: 8px;
}

.phone-input-dropdown .country-list::-webkit-scrollbar-track {
  background: #242424;
}

.phone-input-dropdown .country-list::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
  border: 2px solid #242424;
}
